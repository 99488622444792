import React, { useEffect, useState } from "react";
import Hero from "../../components/home-page-sections/Hero/Hero";
import "./PricingPage.css";
import popularBadge from "../../assets/popular-badge.png";

import logo1 from "../../assets/Logo.png";
import logo2 from "../../assets/logo-company.svg";
import logo3 from "../../assets/logo-company2.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const PricingPage = () => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}pricing/`)
      .then((res) => {
        setPlans(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Pricing | Street Suite</title>
        <meta
          name="description"
          content="Join Street Suite – a trading tool which will provide you with daily trading alerts and insights you can trust, with all the information to back it up."
        />
      </Helmet>

      <div className="pricing-wrapper">
        <Hero>
          <h1>
            <span>Transparent</span> Pricing for All
          </h1>
          <p>
            At street Suite it’s all about transparent pricing - no hidden fees,
            just straight forward honest rates
          </p>
        </Hero>

        <div className="mx-auto px-4 gap-[16px] lg:gap-8 pricing-container columns-8 flex items-start justify-around py-[0px] lg:py-28">
          {/* first plane */}
          <div className="gradient-border lg:w-1/3">
            <div className="plane plane-pricing recommended">
              <div className="plane-body flex flex-col justify-center items-center px-[16px] py-[32px] lg:px-8 lg:py-16">
                <h5 className="plane-title">{plans[0]?.title}</h5>
                <div className="line"></div>
                <ul className="plane-points space-y-5 px-[5px] lg:px-4">
                  <li className="available ">
                    <svg
                      className="true"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                        fill="#53ACFF"
                      />
                    </svg>
                    <svg
                      className="false"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                        fill="white"
                      />
                    </svg>
                    Lorem ipsum dolor sit amet.
                  </li>

                  <li className="available">
                    <svg
                      className="true"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                        fill="#53ACFF"
                      />
                    </svg>
                    <svg
                      className="false"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                        fill="white"
                      />
                    </svg>
                    Lorem ipsum dolor sit amet.
                  </li>

                  <li className="available">
                    <svg
                      className="true"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                        fill="#53ACFF"
                      />
                    </svg>
                    <svg
                      className="false"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                        fill="white"
                      />
                    </svg>
                    Lorem ipsum dolor sit amet.
                  </li>

                  <li className="not-available">
                    <svg
                      className="true"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                        fill="#53ACFF"
                      />
                    </svg>
                    <svg
                      className="false"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                        fill="white"
                      />
                    </svg>
                    Lorem ipsum dolor sit amet.
                  </li>

                  <li className="not-available">
                    <svg
                      className="true"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                        fill="#53ACFF"
                      />
                    </svg>
                    <svg
                      className="false"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                        fill="white"
                      />
                    </svg>
                    Lorem ipsum dolor sit amet.
                  </li>

                  <li className="not-available">
                    <svg
                      className="true"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                        fill="#53ACFF"
                      />
                    </svg>
                    <svg
                      className="false"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                        fill="white"
                      />
                    </svg>
                    Lorem ipsum dolor sit amet.
                  </li>
                </ul>
                <div className="line"></div>
                <div className="plane-price">
                  <p>
                    <span>${plans[0]?.amount}</span> / week
                  </p>
                </div>
                <div className="plane-action">
                  <Link to={`/checkout/${plans[0]?.id}`}>Choose</Link>
                </div>
              </div>
            </div>
          </div>
          {/* End of first plane */}

          {/* second and recommended plane */}
          <div className="gradient-border popular lg:w-1/3">
            <div className="recommended-plane">
              <div className="plane plane-pricing popular">
                <img src={popularBadge} alt="" />
                <div className="plane-body flex flex-col justify-center items-center  px-[16px] py-[32px] lg:px-8 lg:py-16">
                  <h5 className="plane-title">{plans[1]?.title}</h5>
                  <div className="line"></div>
                  <ul className="plane-points space-y-5 px-4">
                    <li className="available ">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>

                    <li className="available">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>

                    <li className="available">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>

                    <li className="available">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>

                    <li className="available">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>

                    <li className="available">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>

                    <li className="not-available">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>

                    <li className="not-available">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>

                    <li className="not-available">
                      <svg
                        className="true"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.74156 0.270361C10.0861 0.630843 10.0861 1.21531 9.74156 1.57576L3.85919 7.72963C3.5146 8.09012 2.95601 8.09012 2.61138 7.72963L0.258433 5.26808C-0.0861444 4.90759 -0.0861444 4.32322 0.258433 3.96272C0.603011 3.60223 1.16169 3.60223 1.50627 3.96272L3.2353 5.77147L8.49379 0.270361C8.83838 -0.0901204 9.39697 -0.0901204 9.74156 0.270361Z"
                          fill="#53ACFF"
                        />
                      </svg>
                      <svg
                        className="false"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                          fill="white"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet.
                    </li>
                  </ul>
                  <div className="line"></div>
                  <div className="plane-price">
                    <p>
                      <span>${plans[1]?.amount}</span> / month
                    </p>
                  </div>
                  <div className="plane-action">
                    <Link to={`/checkout/${plans[1]?.id}`}>Choose</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end of second and recommended plane */}
        </div>

        {/* comparison between our company featurs and other companies features */}
        <div className="setting-ourselves mx-auto px-4 pb-20">
          <h5>Setting Ourselves Apart</h5>
          <p>The Unique Attributes that Distinguish Us in the Market</p>

          <div className="feature-comparison flex gap-[16px] lg:gap-12 items-center justify-between">
            {/* our company features */}

            <div className="ourFeature-card w-1/3">
              <div className="card-content">
                <div className="card-body">
                  <div className="card-header">
                    <img src={logo1} alt="" />
                    <h6>Our Features</h6>
                  </div>
                  <div className="features-list">
                    <ul>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="line"></div>

            <div className="other-company-features w-2/3 flex gap-6">
              {/* other companies features  */}
              <div className="ourFeature-card w-1/2">
                <div className="card-content">
                  <div className="card-body">
                    <div className="card-header">
                      <img src={logo2} alt="" />
                    </div>
                    <div className="features-list">
                      <ul>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ourFeature-card w-1/2">
                <div className="card-content">
                  <div className="card-body">
                    <div className="card-header">
                      <img src={logo3} alt="" />
                    </div>
                    <div className="features-list">
                      <ul>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.66205 0.337951C9.21145 -0.11265 8.48086 -0.11265 8.0303 0.337951L4.99998 3.36832L1.96966 0.337982C1.51904 -0.11262 0.78858 -0.11262 0.337963 0.337982C-0.112655 0.788584 -0.112655 1.51917 0.337963 1.9697L3.36813 5.00002L0.337963 8.03034C-0.112655 8.4808 -0.112655 9.21142 0.337963 9.66204C0.78858 10.1127 1.5192 10.1127 1.96982 9.66204L4.99998 6.63187L8.03015 9.66204C8.48083 10.1127 9.21142 10.1127 9.66202 9.66204C10.1126 9.21142 10.1126 8.48096 9.66202 8.03034L6.63168 5.00002L9.66205 1.9697C10.1127 1.51914 10.1127 0.788553 9.66205 0.337951Z"
                              fill="white"
                            />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPage;
